@charset "UTF-8";
@import url("./vars.css");
@import url("../core-css/viewport.css");
@import url("../normalize/normalize.css");
@import url("../core-css/rem.css");
@import url("../core-css/element.css");
@import url("../core-css/class-rules.css");
@import url("../core-css/animations.css");
@import url("../core-css/fonts.css");
@import url("../animate.css/animate.css");
@import url("./dialog.css");
@import url("./icons.css");
@import url("./contact.css");
@import url("./header.css");
@import url("./footer.css");

html, body {
	min-height: 100vh;
}

body {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	background-attachment: fixed;
}

[data-scene="lake"] {
	background-image: var(--lake-background-h);
}

[data-scene="river"] {
	background-image: var(--river-background-h);
}

dialog {
	border: none;
}

main {
	width: 95vw;
	background-color: rgba(255,255,255,0.6);
	margin: 0.8rem 2.5vw;
	padding: 1.2rem;
	border-radius: 0.5rem;
}

.directory-listings {
	grid-template-columns: repeat(auto-fill, minmax(250px, 600px));
	grid-gap: 0.8em;
}

.directory-listings .card {
	line-height: 1.8;
}

@media (min-width: 801px) {
	.directory-listings figure {
		max-width: 40%;
		margin-left: 0;
	}
}

@media (orientation: landscape and min-width: 1025), (orientation: portrait and max-height: 768px) {
	body[data-scene="lake"] {
		background-image: var(--lake-background-f);
	}

	body[data-scene="river"] {
		background-image: var(--river-background-f);
	}
}

@media (orientation: landscape and max-width: 640px), (orientation: portrait and max-height: 480px) {
	body[data-scene="lake"] {
		background-image: var(--lake-background-l);
	}

	body[data-scene="river"] {
		background-image: var(--river-background-l);
	}
}

@media (orientation: landscape and max-width: 320px), (orientation: portrait and max-height: 240px) {
	body[data-scene="lake"] {
		background-image: var(--lake-background-m);
	}

	body[data-scene="river"] {
		background-image: var(--river-background-m);
	}
}

@media (orientation: portrait and max-width: 160px), (orientation: portrait and max-height: 120px) {
	body[data-scene="lake"] {
		background-image: var(--lake-background-s);
	}

	body[data-scene="river"] {
		background-image: var(--river-background-s);
	}
}
